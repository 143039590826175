<section class="section-1">
    <div class="row Rpadding">
        <div class="col-sm-4">
            <img src="/assets/imgs/logo.png" alt="">
        </div>
        <div class="col-sm-4">
            <h4>Menu</h4>
            <ul class="navbar-nav">
                <li class="nav-item">
                  <a class="nav-link pointer" routerLink="/home">Home <span class="sr-only">(current)</span></a>
                </li>
                <li class="nav-item">
                  <a class="nav-link pointer" routerLink="/about-us">About Us</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link pointer" (click)="partnersLink()" >Distributorship & Partner</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link pointer" (click)="servicesLink()">Service</a>
                  </li>
              </ul>
        </div>
        <!-- <div class="col-sm-3">
            <h4>Support</h4>
            <ul class="navbar-nav">
                <li class="nav-item">
                    <a class="nav-link pointer" routerLink="/contact-us" >Contact Us</a>
                  </li>
              </ul>
        </div> -->
        <div class="col-sm-4">
            <h4>Contact</h4>
            <p>sales@multi-tech.com.hk</p>
            <p>(852) 2104 2168</p>
        </div>
    </div>
</section>
<section class="section-2">
    <div class="row Rpadding">
        <div class="col-sm-12 px-0 footer-bottom">
            <div class="copyright"><p>Copyright © Multi-Tech Group</p></div>
            <!-- <div>
                <ul class="navbar-nav">
                    <li class="nav-item">
                      <a class="nav-link pointer" >Privacy Policy</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link pointer" >Terms and Conditions</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link pointer" >Cookies</a>
                    </li>
                  </ul>
            </div> -->
        </div>
    </div>
</section>
