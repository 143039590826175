import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { ContactUsComponent } from './contact-us/contact-us.component';

/*owl slider */
import { CarouselModule } from 'ngx-owl-carousel-o';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; 
import firebase from 'firebase';

 import { RecaptchaModule } from 'ng-recaptcha';
 import { NgxCaptchaModule } from 'ngx-captcha'
// import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from "ng-recaptcha";

import { DataService } from './data.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AgmCoreModule } from '@agm/core';
import { ToastrModule } from 'ngx-toastr';
import { SafePipe } from './safe.pipe';
var firebaseConfig = {
  apiKey: "AIzaSyAAlv_IXCVXFWvrMH56llN_Jo7rHyya4-M",
  authDomain: "multitech-c124f.firebaseapp.com",
  databaseURL: "https://multitech-c124f.firebaseio.com",
  projectId: "multitech-c124f",
  storageBucket: "multitech-c124f.appspot.com",
  messagingSenderId: "109178740570",
  appId: "1:109178740570:web:01196c3ceb650406ce1d63",
  measurementId: "G-VQVW55VCES"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    AboutUsComponent,
    ContactUsComponent,
    SafePipe
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    /*owl slider */
    CarouselModule,
    RecaptchaModule,
    NgxCaptchaModule,
    // RecaptchaV3Module,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDGmkGmJzanvRjTGbgjMIoiXGSTTnHnJNE'
    }),
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot(),
  ],
  providers: [DataService, 
   // { provide: RECAPTCHA_V3_SITE_KEY, useValue: "<YOUR_SITE_KEY>" }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
