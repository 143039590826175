import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { OwlOptions } from 'ngx-owl-carousel-o';
import { DataService } from '../data.service';
import { ToastrService } from 'ngx-toastr';

import { NgxCaptchaModule, ReCaptcha2Component } from 'ngx-captcha';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})

export class HomeComponent implements OnInit {
  @ViewChild('captchaElem', { static: false }) captchaElem: ReCaptcha2Component;

  public sitekey: string;
  public contactForm: FormGroup;
  public sliderImagUrl: any = [];
  public bulletsData: any[];
  public PartnersData: any = [];
  public serviceData: any = [];
  public chooseUsData: any = [];
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    margin: 20,
    navText: ['<i class="fas fa-chevron-left"></i>', '<i class="fas fa-chevron-right"></i>'],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 4
      }
    },
    nav: true
  }
  public submitted: boolean = false;

  constructor(public service: DataService,
    public fb: FormBuilder,
    private toastr: ToastrService) { }

  ngOnInit(): void {

    //for Lcoal & Live firebase domain
    this.sitekey = '6LdP1zUaAAAAACC9LQNMJ0A6xTPpyQhBrXW0koU4';
    // Secret Key 6LdP1zUaAAAAALXCJScyuC0GIIwqnZ-1GVYzGlBd

    this.contactForm = this.fb.group({
      recaptcha: ['', Validators.required],
      companyName: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(40)]],
      fullName: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(25)]],
      PhoneNumber: ['', Validators.required],
      Request: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(120)]],
      EmailAddress: ['', Validators.compose([
        Validators.required,
        Validators.pattern('^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$'),
      ])],
    })
    this.getSliderImage();
    this.getBullteData();
    this.getChooseUsData();
    this.getPartners();
    this.getService();
  }

  get f() { return this.contactForm.controls; }


  public getSliderImage = () => {
    this.sliderImagUrl = this.service.allImageUrl;
    this.service.imageObs.subscribe(() => {
      this.sliderImagUrl = this.service.allImageUrl;
    })
  }

  public getBullteData = () => {
    this.bulletsData = this.service.bulletsData;
    this.service.bulletsOBS.subscribe(() => {
      this.bulletsData = this.service.bulletsData;
    })
  }

  getChooseUsData() {
    this.chooseUsData = this.service.chooseUsData;
    this.service.chooseUsOBS.subscribe(() => {
      this.chooseUsData = this.service.chooseUsData;
    })
  }

  public getPartners = () => {
    this.PartnersData = this.service.partnersData;
    this.service.partnersOBS.subscribe((res) => {
      this.PartnersData = this.service.partnersData;
    })
  }

  public getService = () => {
    this.serviceData = this.service.ServiceData;
    this.service.ServicOBS.subscribe(() => {
      this.serviceData = this.service.ServiceData;
    })
  }


  handleSuccess(eventData) {
    // console.log(this.contactForm.value);
  }

  handleExpire() {
    this.contactForm.get('recaptcha').setValue('');
    this.captchaElem.resetCaptcha();
  }

  public send() {
    if (!this.contactForm.valid) {
      this.submitted = true;
      return;
    }
    this.submitted = false;
    var data = this.contactForm.value;
    delete data.recaptcha;
    this.service.sendRequest(this.contactForm.value);
    this.contactForm.reset();
    //this.captchaElem.reloadCaptcha();
    this.captchaElem.resetCaptcha();
  }

  public toHyperLink = (data) => {
    if (data != '') {
      window.open(data, "_blank");
    }
  }

}
