<app-header></app-header>
<section class="main-cont">
  <section class="section-1">
    <div class="row Rpadding banner" *ngIf="BgBanner && BgBanner.url"
      [ngStyle]="{'background-image': 'url(' + BgBanner.url + ')'}">
      <div class="col-sm-12 px-0">
        <h2 class="primary">Contact US</h2>
      </div>
    </div>
  </section>

  <section class="section-2" id="contact">
    <div class="row Rpadding">
      <div class="col-sm-7 px-0">
        <h2 class="">Get in Touch With Us</h2>
        <h2 class="bold primary">Call us any time</h2>

        <form [formGroup]='contactForm'>
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <input formControlName='companyName' class="form-control" type="text" name=""
                  placeholder="Comopany Name">
                <div *ngIf="submitted && f.companyName.errors" class="redErrorText pl-2">
                  <div *ngIf="f.companyName.errors.required">*Company Name is required</div>
                  <div *ngIf="f.companyName.errors.minlength || f.companyName.errors.maxlength">*Company Name length
                    should be between 3 to 40 characters</div>
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <input formControlName='fullName' class="form-control" type="text" name="" placeholder="Full Name">
                <div *ngIf="submitted && f.fullName.errors" class="redErrorText pl-2">
                  <div *ngIf="f.fullName.errors.required">*Full Name is required</div>
                  <div *ngIf="f.fullName.errors.minlength || f.fullName.errors.maxlength">*Full Name length should be
                    between 3 to 25 characters</div>
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <input formControlName='EmailAddress' class="form-control" type="text" name=""
                  placeholder="Email Address">
                <div *ngIf="submitted && f.EmailAddress.errors" class="redErrorText pl-2">
                  <div *ngIf="f.EmailAddress.errors.required">*Email is required</div>
                  <div *ngIf="f.EmailAddress.errors.pattern">*Email pattern is invalid, Kindly enter valid email.</div>
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <input formControlName='PhoneNumber' class="form-control" type="text" name="" placeholder="Phone No.">
                <div *ngIf="submitted && f.PhoneNumber.errors" class="redErrorText pl-2">
                  <div *ngIf="f.PhoneNumber.errors.required">*Phone Numer is required</div>
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <!-- <input formControlName='Request' class="form-control" type="text" name="" placeholder="Request"> -->
                <textarea rows="3" formControlName='Request' class="form-control" placeholder="Request"></textarea>
                <div *ngIf="submitted && f.Request.errors" class="redErrorText pl-2">
                  <div *ngIf="f.Request.errors.required">*Request is required</div>
                  <div *ngIf="f.Request.errors.minlength || f.Request.errors.maxlength">*Request length should be
                    between 10 to 120 characters</div>
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">

                <ngx-recaptcha2 #captchaElem [siteKey]="sitekey" (success)="handleSuccess($event)"
                  formControlName="recaptcha" (expire)="handleExpire()">
                </ngx-recaptcha2>

                <div *ngIf="submitted && f.recaptcha.errors" class="redErrorText pl-2">
                  <div *ngIf="f.recaptcha.errors.required">*Check if you are not robot</div>
                </div>
              </div>
            </div>

            <div class="col-sm-12 text-center">
              <button class="btn btn-primary" type="button" (click)='send()'>Send</button>
            </div>
          </div>

        </form>
      </div>
      <div class="col-sm-5">
        <div class="row">
          <div class="col-sm-12 telecom" *ngFor='let data of contact_InfoData '>
            <div [innerHTML]='data.text | safe'></div>
          </div>

        </div>
      </div>
    </div>
  </section>

  <section class="section-3">
    <agm-map #gm id="mapSunSpotId" style="width: 100%; height: 300px;" [zoom]="15" [minZoom]="0"
      [restriction]="mapOptions" [latitude]="locationOBJ.lat" [longitude]="locationOBJ.long">
      <agm-marker [latitude]="locationOBJ.lat" [longitude]="locationOBJ.long" [iconUrl]="icon">
        <agm-info-window>

        </agm-info-window>
      </agm-marker>
    </agm-map>
    <!-- <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d41759.54415520289!2d114.18414251921637!3d22.313679924240365!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3404014870c68221%3A0x9d85c1259b6d92ac!2sMg%20Tower%20Podium%2C%200%2C%20Kwun%20Tong%2C%20Hong%20Kong!5e0!3m2!1sen!2s!4v1605854623877!5m2!1sen!2s" width="100%" height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe> -->

  </section>
</section>
<app-footer></app-footer>
