import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { DataService } from '../data.service';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit {

  constructor(public service:DataService,private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.getIntroduction();
    this.getVission();
    this.getGeographic();
    this.getClientData();
    this.getBGImage();
  }
  public introductionData =[];
  public vission;
  public GeographicDATA;
  public client;
  public BgBanner;

  public getIntroduction = () => {
    this.introductionData = this.service.introductiondata;
    this.service.introductionOBS.subscribe(()=>{
      this.introductionData = this.service.introductiondata;
    })
  }
  public getVission =() => {
    this.vission = this.service.CompanyVissionData;
    this.service.vissionOBS.subscribe(()=>{
      this.vission = this.service.CompanyVissionData;
    })
  }

  public getGeographic =  () => {
    this.GeographicDATA = this.service.GeographicData;
    this.service.GeographicOBS.subscribe(()=>{
      this.GeographicDATA = this.service.GeographicData;
    })
  }

  public getClientData = () => {
    this.client = this.service.ClientData;
    this.service.ClientOBS.subscribe(()=>{
      this.client = this.service.ClientData;
    })
  }

  public getBGImage = () => {
     this.BgBanner  = this.service.bannerImage[0];
     this.service.bannerOBS.subscribe(()=>{
      this.BgBanner  = this.service.bannerImage[0];
     })
  }
  

}
