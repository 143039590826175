import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit  {

  public activeLink:any;
  
  constructor(public router:Router,private route: ActivatedRoute) {
  }
  ngAfterViewInit(): void{
    const divId=  this.route.snapshot.params.id
  if(divId)
  {
    document.getElementById(divId).scrollIntoView();
  }
  }

  ngOnInit(): void {
    
  }
  partners(){
    var url = this.router.routerState.snapshot.url;
    if(url.includes('home')) {
      document.getElementById('partner').scrollIntoView();
    }
    this.router.navigate(['/home/partner']);
  }
  services(){
    var url = this.router.routerState.snapshot.url;
    if(url.includes('home')) {
      document.getElementById('service').scrollIntoView();
    }
    this.router.navigate(['/home/service']);
  }

}
