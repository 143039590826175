<app-header></app-header>
<section class="section-1" id="home">
  <div class="row slider">
    <div class="col-sm-12 px-0">
      <div id="carouselExampleIndicators" class="carousel slide " data-ride="carousel">
        <ol class="carousel-indicators">
          <li *ngFor='let data of sliderImagUrl;let i=index' data-target="#carouselExampleIndicators"
            attr.data-slide-to="{{i}}" class="{{ (i == 0) ? 'active' : '' }}"></li>
        </ol>
        <div class="carousel-inner">
          <div *ngFor='let data of sliderImagUrl;let i=index' class="carousel-item" [class.active]='i==0'>
            <img class="carousel-items" [src]="data.img_url">
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="section-2">
  <div class="row Rpadding">
    <div class="col-sm-4 px-0" *ngFor='let data of bulletsData'>
      <div class="card">
        <div class="row no-gutters align-items-center">
          <div class="col-sm-12 d-flex justify-content-center">
            <img [src]="data.img_url" class="card-img mr-3">
            <!-- </div>
                  <div class="col-sm-9"> -->
            <div class="card-body card-body1">
              <h5 class="card-title">{{data.title}}</h5>
              <p>{{data.description}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</section>

<section class="section-3" id="about">
  <div class="row Rpadding py-5">
    <div class="col-sm-9">
      <h3 class="bold">Why <span class="primary">Choose Us?</span></h3>
      <!-- <p>Multi-Tech Group (Multi-Tech Telecom) was established in 1997 and
              primarily engaged in the telecommunication business to provide communication
              networks and solution to SME customers. Over the years, Multi-Tech has
              enjoyed a fine reputation for providing world-class products, solutions
              and services in Hong Kong, Macau and the PRC.</p> -->
      <p [innerHTML]='chooseUsData[0]'></p>
      <button class="btn btn-primary" type="button" routerLink="/about-us">Read More</button>
    </div>
    <div class="col-sm-6">
      <img src="/assets/imgs/about.png" width="100%" alt="">
    </div>
  </div>
</section>

<section class="section-4" id="partner">
  <div class="row Rpadding">

    <div class="col-sm-12 px-0">
      <h3 class="bold primary">Distributorship & Partner</h3>
      <!--use this commented code for the dynamic slider-->
      <!-- <owl-carousel-o [options]="customOptions">

                <ng-template *ngFor="let data of slideData; index as i"  carouselSlide>{{i}}</ng-template>

            </owl-carousel-o> -->

      <owl-carousel-o [options]="customOptions" *ngIf="PartnersData.length>=3">
        <ng-template carouselSlide *ngFor='let data of PartnersData'>
          <div class="card pointer" (click)='toHyperLink(data.hyperLink)'>
            <img [src]="data.image" class="card-img-top" alt="">
            <div class="card-body">
              <h5 class="card-title">{{data.title}}</h5>
            </div>
          </div>
        </ng-template>
      </owl-carousel-o>
      <div class="row" *ngIf="PartnersData.length<3">
        <div class="col-md-3" *ngFor='let data of PartnersData'>
          <div class="card pointer shadow rounded" (click)='toHyperLink(data.hyperLink)'>
            <img [src]="data.image" class="card-img-top" alt="">
            <div class="card-body">
              <h5 class="card-title">{{data.title}}</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</section>

<section class="section-4" id="service">
  <div class="row Rpadding">
    <div class="col-sm-12 px-0">
      <h3 class="bold primary">Services</h3>

      <owl-carousel-o [options]="customOptions" *ngIf="serviceData.length>=3">
        <ng-template carouselSlide *ngFor='let data of serviceData'>

          <div class="card pointer " (click)='toHyperLink(data.hyperLink)'>
            <img [src]="data.image" class="card-img-top" alt="">
            <div class="card-body">
              <h5 class="card-title">{{data.title}}</h5>
            </div>
          </div>
        </ng-template>
      </owl-carousel-o>
      <div class="row" *ngIf="serviceData.length<3">
        <div class="col-md-3" *ngFor='let data of serviceData'>
          <div class="card pointer shadow rounded " (click)='toHyperLink(data.hyperLink)'>
            <img [src]="data.image" class="card-img-top" alt="">
            <div class="card-body">
              <h5 class="card-title">{{data.title}}</h5>
            </div>
          </div>
        </div>
      </div>
      <br>
    </div>
  </div>
</section>

<section class="section-6" id="contact">
  <div class="row Rpadding">
    <div class="col-sm-12 px-0">
      <h2>Get in Touch With Us</h2>
      <h2 class="bold primary">Call us any time</h2>
      <div class="row">
        <div class="col-sm-12 px-0">
          <form [formGroup]='contactForm'>
            <div class="row">
              <div class="col-sm-5">
                <div class="form-group">
                  <input formControlName='companyName' class="form-control" type="text" name=""
                    placeholder="Comopany Name">
                  <div *ngIf="submitted && f.companyName.errors" class="redErrorText pl-2">
                    <div *ngIf="f.companyName.errors.required">*Company Name is required</div>
                    <div *ngIf="f.companyName.errors.minlength || f.companyName.errors.maxlength">*Company Name length
                      should be between 3 to 40 characters</div>
                  </div>
                </div>
              </div>
              <div class="col-sm-5">
                <div class="form-group">
                  <input formControlName='fullName' class="form-control" type="text" name="" placeholder="Full Name">
                  <div *ngIf="submitted && f.fullName.errors" class="redErrorText pl-2">
                    <div *ngIf="f.fullName.errors.required">*Full Name is required</div>
                    <div *ngIf="f.fullName.errors.minlength || f.fullName.errors.maxlength">*Full Name length should be
                      between 3 to 25 characters</div>
                  </div>
                </div>
              </div>
              <div class="col-sm-5">
                <div class="form-group">
                  <input formControlName='EmailAddress' class="form-control" type="text" name=""
                    placeholder="Email Address">
                  <div *ngIf="submitted && f.EmailAddress.errors" class="redErrorText pl-2">
                    <div *ngIf="f.EmailAddress.errors.required">*Email is required</div>
                    <div *ngIf="f.EmailAddress.errors.pattern">*Email pattern is invalid, Kindly enter valid email.
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-5">
                <div class="form-group">
                  <input formControlName='PhoneNumber' class="form-control" type="tel" name="" placeholder="Phone No.">
                  <div *ngIf="submitted && f.PhoneNumber.errors" class="redErrorText pl-2">
                    <div *ngIf="f.PhoneNumber.errors.required">*Phone Numer is required</div>
                  </div>
                </div>
              </div>
              <div class="col-sm-5">
                <div class="form-group">
                  <!-- <input formControlName='Request' class="form-control" type="text" name="" placeholder="Request"> -->
                  <textarea rows="3" formControlName='Request' class="form-control" placeholder="Request"></textarea>
                  <div *ngIf="submitted && f.Request.errors" class="redErrorText pl-2">
                    <div *ngIf="f.Request.errors.required">*Request is required</div>
                    <div *ngIf="f.Request.errors.minlength || f.Request.errors.maxlength">*Request length should be
                      between 10 to 120 characters</div>
                  </div>
                </div>
              </div>
              <div class="col-sm-5">
                <div class="form-group">

                  <ngx-recaptcha2 #captchaElem [siteKey]="sitekey" (success)="handleSuccess($event)"
                    formControlName="recaptcha" (expire)="handleExpire()">
                  </ngx-recaptcha2>

                  <div *ngIf="submitted && f.recaptcha.errors" class="redErrorText pl-2">
                    <div *ngIf="f.recaptcha.errors.required">*Check if you are not robot</div>
                  </div>
                </div>
              </div>

              <div class="col-sm-12 text-center">
                <button class="btn btn-primary" type="button" (click)='send()'>Send</button>
              </div>
            </div>

          </form>
        </div>
      </div>
    </div>
  </div>
</section>


<app-footer></app-footer>
