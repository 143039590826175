<nav class="navbar navbar-expand-md fixed-top">
  <div class="logo">
    <a class="navbar-brand" href="#"><img src="/assets/imgs/logo.png" alt=""></a>
    <!-- <p><small>Multi-Tech (China) Telecom System Ltd.</small></p> -->
  </div>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav"
    aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"><i class="fas fa-bars"></i></span>
  </button>
  <div class="collapse navbar-collapse" id="navbarNav">
    <ul class="navbar-nav">
      <li class="nav-item" routerLink="/home"  [routerLinkActive]="['active']">
        <a class="nav-link">Home</a>
      </li>
      <li class="nav-item"
        routerLink="/about-us"  [routerLinkActive]="['active']">
        <a class="nav-link">About Us</a>
      </li>
      <li class="nav-item" (click)=" partners()" [routerLinkActive]="['active']">
        <a class="nav-link">Distributorship & Partner</a>
      </li>
      <li class="nav-item" (click)="services()" [routerLinkActive]="['active']">
        <a class="nav-link">Service</a>
      </li>
      <li class="nav-item" routerLink="/contact-us" [routerLinkActive]="['active']">
        <a class="nav-link">Contact Us</a>
      </li>
    </ul>
    <!-- <div class=" align-items-center menu-right">
         <div>
          <img src="/assets/imgs/phone.svg" alt="">
          <small>(852) 2104 2168)</small>
         </div>
          <button class="btn btn-primary ml-4" type="button">Get Started</button>
    </div> -->

  </div>
</nav>
