import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { DataService } from '../data.service';

import { NgxCaptchaModule, ReCaptcha2Component } from 'ngx-captcha';
@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {
  @ViewChild('captchaElem', { static: false }) captchaElem: ReCaptcha2Component;

  lat: number = 51.673858;
  lng: number = 7.815982;
  public sitekey: string;
  public contact_InfoData: any = [];
  public BgBanner: any;
  public contactForm: FormGroup;
  public expandMap: boolean = false;
  public locationOBJ: any = {
    lat: 51.673858,
    lng: 7.815982
  }
  public icon = {
    url: '/assets/imgs/pin.svg',
    scaledSize: {
      width: '40px',
      height: '40px'
    }
  }
  public mapOptions: any = {
    latLngBounds: {
      north: 85,
      south: -85,
      west: -180,
      east: 180
    },
    strictBounds: true
  }

  public submitted: boolean = false;

  constructor(public service: DataService,
    public fb: FormBuilder,
    private toastr: ToastrService,
  ) {

    //for Lcoal & Live firebase domain
    this.sitekey = '6LdP1zUaAAAAACC9LQNMJ0A6xTPpyQhBrXW0koU4';
    // Secret Key 6LdP1zUaAAAAALXCJScyuC0GIIwqnZ-1GVYzGlBd;

    this.getLocation();
  }

  ngOnInit(): void {
    // this.captchaElem.resetCaptcha();
    this.contactForm = this.fb.group({
      recaptcha: ['', Validators.required],
      companyName: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(40)]],
      fullName: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(25)]],
      PhoneNumber: ['', Validators.required],
      Request: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(120)]],
      EmailAddress: ['', Validators.compose([
        Validators.required,
        Validators.pattern('^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$'),
      ])],
    })
    this.getContactInfo();
    this.getBGImage();
  }

  get f() { return this.contactForm.controls; }

  getContactInfo() {
    this.contact_InfoData = this.service.contactInfo
    this.service.contactInfoOBS.subscribe(() => {
      this.contact_InfoData = this.service.contactInfo;
    })
  }

  getBGImage() {
    this.BgBanner = this.service.contact_Info_BG_img[0];
    this.service.contact_Info_BG_img_OBS.subscribe(() => {
      this.BgBanner = this.service.contact_Info_BG_img[0];
    })
  }

  getLocation() {
    this.locationOBJ = this.service.latLong[0];
    this.service.locationOBS.subscribe((res: any) => {
      this.locationOBJ = this.service.latLong[0];
    })
  }

  handleSuccess(eventData) {
    // console.log(this.contactForm.value);
  }

  handleExpire() {
    this.contactForm.get('recaptcha').setValue('');
    this.captchaElem.resetCaptcha();
  }

  send() {
    if (!this.contactForm.valid) {
      this.submitted = true;
      return;
    }
    this.submitted = false;
    var data = this.contactForm.value;
    delete data.recaptcha;
    this.service.sendRequest(this.contactForm.value);
    this.contactForm.reset();
    //this.captchaElem.reloadCaptcha();
    this.captchaElem.resetCaptcha();

  }

}