<app-header></app-header>
<section class="main-cont">
    <!-- <img [src]="BgBanner.url"> -->
    <section class="section-1">
        <!-- [ngStyle]="{'background-image': 'url(' + BgBanner.url + ')'}" -->
        <div class="row Rpadding banner"   [ngStyle]="{'background-image': 'url(' + BgBanner.url + ')'}">
            <div class="col-sm-12 px-0">
                <h2 class="primary">ABOUT US</h2>
            </div>
        </div>
    </section>
    <section class="section-2">
        <div class="row Rpadding py-5">
            <div class="col-sm-12">
                <h2 class="bold primary">Introduction</h2>
                <div [innerHTML]='introductionData[0]'></div>
               
            </div>
        </div>
    </section>
    <section class="section-3">
        <div class="row Rpadding py-5">
            <div class="col-sm-12">
                <div class="row py-5 vision">
                    <div class="col-sm-4 text-center">
                        <h1>The Company’s <br> <span class="primary">Vision</span></h1>
                    </div>
                    <div style="color: white;" class="col-sm-8" [innerHTML]='vission[0]'>
                  
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="section-4">
        <div class="row Rpadding py-5">
            <div class="col-sm-12">
                <h2 class="bold primary">Geographic Coverage</h2>
                <div [innerHTML]='GeographicDATA[0]'></div>
                <!-- <p class="pb-2">Multi-Tech and its affiliates have offices in several major cities in China, Hong Kong, and Macau. Technical staff and representatives are based in these locations to serve customers’ needs providing them with the best service and support.</p> -->
            </div>
        </div>
    </section>

    <section class="section-5">
        <div class="row Rpadding py-5">
            <div class="col-sm-12 clients px-0">
                <div class="row">
                    <div class="col-sm-12">
                        <h2>Our Clients</h2>
                        <!-- <p>Our clientele covers industries of</p> -->
                        <div class="row">
                            <div class="col-sm-8 pl-4" [innerHTML]='client[0]'>
                                <!-- <ul class="pl-0">
                                    <li>Banking</li>
                                    <li>Hospitality</li>
                                    <li>Manufacturer</li>
                                </ul> -->
                            </div>
                          
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</section>

<app-footer></app-footer>