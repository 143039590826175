import { Injectable } from '@angular/core';
import firebase from 'firebase';
import { Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  public imageObs: Subject<any> = new Subject();
  public allImageUrl = [];
  public bulletsData = [];
  public bulletsOBS: Subject<any> = new Subject();
  public introductiondata: any = [];
  public introductionOBS: Subject<any> = new Subject();
  public CompanyVissionData = [];
  public vissionOBS: Subject<any> = new Subject();
  public chooseUsData = [];
  public chooseUsOBS: Subject<any> = new Subject();
  public GeographicData: any = [];
  public GeographicOBS: Subject<any> = new Subject();
  public ClientData: any = [];
  public ClientOBS: Subject<any> = new Subject();
  public bannerImage: any = [];
  public bannerOBS: Subject<any> = new Subject();
  public partnersData: any = [];
  public partnersOBS: Subject<any> = new Subject();
  public ServiceData: any = [];
  public ServicOBS: Subject<any> = new Subject();
  public contactInfo: any = [];
  public contactInfoOBS: Subject<any> = new Subject();
  public contact_Info_BG_img_OBS: Subject<any> = new Subject();
  public contact_Info_BG_img: any = [];
  public latLong: any = [];
  public locationOBS: Subject<any> = new Subject();
  public ContactOBS: Subject<any> = new Subject();

  constructor( private toastr: ToastrService) {
    this.getData();
    this.getBullets();
    this.getChooseUsData();
    this.getIntroduction();
    this.getCompanyVission();
    this.getGeographic();
    this.getClientData();
    this.getBannerImg();
    this.getPartner();
    this.getService();
    this.getContactInfo();
    this.getConatactBannerImg();
    this.getLatlong();
  }

  public getData = () => {
    firebase.database().ref().child('HomeSlider').once('value', (snapshort) => {
      let allData = [];
      let data = snapshort.val();
      for (let key in data) {
        allData.push(data[key]);
      }
      this.allImageUrl = allData;
      this.imageObs.next(allData);
    })
  }

  public getBullets = () => {
    firebase.database().ref().child('Bullets').once('value', (snapshort) => {
      let allData = [];
      let data = snapshort.val();
      for (let key in data) {
        data[key].key = key;
        allData.push(data[key]);
      }
      this.bulletsData = allData;
      this.bulletsOBS.next(allData);
    })
  }

  public getIntroduction = () => {
    firebase.database().ref().child('Introduction').once('value', (snapshort) => {
      let data = snapshort.val();
      for (let key in data) {
        this.introductiondata.push(data[key]);
      }
      this.introductionOBS.next(this.introductiondata);
    })
  }

  public getCompanyVission = () => {
    firebase.database().ref().child('CompanyVission').once('value', (snapShort) => {
      let data = snapShort.val();
      for (let key in data) {
        this.CompanyVissionData.push(data[key]);
      }
      this.vissionOBS.next(this.CompanyVissionData);
    })
  }

  getChooseUsData() {
    firebase.database().ref().child('ChooseUs').once('value', (snapShort) => {
      let data = snapShort.val();
      for (let key in data) {
        this.chooseUsData.push(data[key]);
      }
      this.chooseUsOBS.next(this.chooseUsData);
    })
  }

  public getGeographic = () => {
    firebase.database().ref().child('Geographic').once('value', (snapShort) => {
      let data = snapShort.val();
      for (let key in data) {
        this.GeographicData.push(data[key]);
      }
      this.GeographicOBS.next(this.GeographicData)
    })
  }

  public getClientData = () => {
    firebase.database().ref().child('client').once('value', (snapShort) => {
      let data = snapShort.val();
      for (let key in data) {
        this.ClientData.push(data[key]);
      }
      this.ClientOBS.next(this.ClientData);
    })
  }

  public getBannerImg = () => {
    this.bannerImage = [];
    firebase.database().ref().child('AboutUsBanner').once('value', (snapshort) => {
      let data = snapshort.val();
      for (let key in data) {
        this.bannerImage.push(data[key]);
      }
      this.bannerOBS.next(this.bannerImage);
    })
  }

  public getPartner = () => {
    this.partnersData = [];
    firebase.database().ref().child('Partners').once('value', (snapShort) => {
      let data = snapShort.val();
      for (let key in data) {
        data[key].key = key;
        this.partnersData.push(data[key]);
      }
      this.partnersOBS.next(this.partnersData);
    })
  }

  public getService = () => {
    this.ServiceData = [];
    firebase.database().ref().child('Service').once('value', (snapShort) => {
      let data = snapShort.val();
      for (let key in data) {
        data[key].key = key;
        this.ServiceData.push(data[key]);
      }
      this.ServicOBS.next(this.ServiceData);
    })
  }

  public sendRequest = (obj) => {
    let update = {};
    let postKey = firebase.database().ref().push().key;
    obj.timeStamp = firebase.database.ServerValue.TIMESTAMP as any;
    update[`Requests/${postKey}`] = obj
    firebase.database().ref().update(update).then(() => {
     // this.ContactOBS.next('record Added');
      this.toastr.success('Request Send Successfully')
    })
  }

  public getContactInfo = () => {
    this.contactInfo = [];
    firebase.database().ref().child('ContactInfo').once('value', (snapShort) => {
      let data = snapShort.val();
      for (let key in data) {
        data[key].key = key;
        this.contactInfo.push(data[key]);
      }
      this.contactInfoOBS.next();
    })
  }

  public getConatactBannerImg = () => {
    this.contact_Info_BG_img = [];
    firebase.database().ref().child('Contact_Info').once('value', (snapshort) => {
      let data = snapshort.val();
      for (let key in data) {
        this.contact_Info_BG_img.push(data[key]);
      }
      this.contact_Info_BG_img_OBS.next(this.contact_Info_BG_img);
    })
  }

  public getLatlong = () => {
    firebase.database().ref().child('Location').once('value', (snapShort) => {
      let data = snapShort.val();
      for (let key in data) {
        this.latLong.push(data[key]);
      }
      this.locationOBS.next();
    })
  }

}
